import * as React from 'react';
import {useEffect, useState} from 'react';
import {NavigateFunction, useLocation} from 'react-router-dom';
import Notification, {NotificationType} from "../../common/Notification";
import {currentApiDomain, EMPTY_STRING, getServiceNameFromLocation} from "../../../util/helperUtil";
import {
  Exception,
  GetMultiSiteReportResponse,
  HOME_PAGE_URL,
  INTERNAL_SERVER_ERROR_CODE_ONE,
  RECOGNIZED_RESPONSE_STATUS,
} from "../../../module/AuthAdminModule";
import Link from "@amzn/awsui-components-react/polaris/link";
import ErrorNotification from "../../common/ErrorNotification";
import {ColumnLayout, SpaceBetween} from '@amzn/awsui-components-react';
import {useTranslator} from '../../../localization/Translator';
import {useNavigate} from 'react-router';
import Button from '@amzn/awsui-components-react/polaris/button';
import Loading from '../../common/Loading';

const GenerateMultiSiteReport = () => {
  const t = useTranslator();
  const navigate: NavigateFunction = useNavigate();
  /****  Constants  *****/
  const GET_MULTI_SITE_REPORT = `${currentApiDomain}/GetMultiSiteReport?`;

  /****  States   *****/
  const location = useLocation();
  const serviceName = getServiceNameFromLocation(location);

  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [reportUrl, setReportUrl] = React.useState(EMPTY_STRING);
  const [notification, setNotification] = useState({
    type: NotificationType.INFO, header: "site_report_generation_info_header", message: "site_report_generation_info",
    messageVariable: {}
  });
  useEffect(() => {
    fetchMultiSiteReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchMultiSiteReport = () => {
    setErrorCode(EMPTY_STRING);
    setIsLoading(true);
    const baseUrl = `${GET_MULTI_SITE_REPORT}serviceName=${encodeURIComponent(serviceName)}`;
    fetch(baseUrl, {
      method: "get",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 401) {
          // redirect to authenticate
          window.location.replace(currentApiDomain + "/Authorize");
          return res.json();
        }
        if (RECOGNIZED_RESPONSE_STATUS.includes(res.status)) {
          return res.json();
        }
        throw new Error("Unrecognized response status " + JSON.stringify(res));
      })
      .then((res: any) => {
        // Success:200
        if (res.hasOwnProperty("responseCode")) {
          res = res as GetMultiSiteReportResponse;
          if (res.responseCode === 200) {
            setReportUrl(res.reportUrl)
            setNotification({
              type: NotificationType.SUCCESS,
              header: "multi_site_report_generation_success_response_header_message",
              message: "multi_site_report_generation_success_response_message",
              messageVariable: {}
            })
            return;
          }
          setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
          return;
        }
        // Exception from backend
        if (res.hasOwnProperty("errorId")) {
          res = res as Exception;
          console.error("Unsuccessful API call", res.message, res);
          setErrorCode(res.errorId);
          return;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
        console.error(err.message);
      });
  };

  const responsePageOnClick = () => {
    navigate(HOME_PAGE_URL);
  };

  return isLoading ?
    <Loading/> : <SpaceBetween size={"xxl"} alignItems={"center"}>
      {errorCode && <ErrorNotification errorCode={errorCode}/>}
      <Notification notificationType={notification.type}
                    headerI18nKey={notification.header}
                    messageI18nKey={notification.message}
                    messageI18nKeyVariables={notification.messageVariable}/>
      <ColumnLayout columns={1} borders="horizontal" minColumnWidth={250} className={"margin-bottom-50"}>
        <Link fontSize={"heading-m"}
              href={reportUrl}>{t.translate("multi_site_report_generation_link_message")}</Link>
      </ColumnLayout>
      <Button
        formAction="none"
        variant="primary"
        onClick={responsePageOnClick}>
        {t.translate("back_button_name")}
      </Button>
    </SpaceBetween>;

};
export default GenerateMultiSiteReport;
